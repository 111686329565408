// **********************************************************************

global.testing = false;

// **********************************************************************

global.debugging = global.testing;
global.version = '1.0';
global.items_max = 25; // pagination - max.items na stránke
global.title = 'Datadream';
global.creator = 'Datadream s.r.o.';

// **********************************************************************
// DATABAZA - LINK
// **********************************************************************
global.web = 'https://www.datadream.sk';
global.db_url = global.web + '/app_web/';
global.images = global.web + '/public/images/';
global.pdf = global.web + '/public/pdf/';
global.www = 'www.datadream.sk';
global.gallery = global.web + '/public/images/gallery/';
global.mats = global.web + '/public/images/mats/';

// **********************************************************************
// COLORS
// **********************************************************************
global.theme_back = '#F4F5FA';
global.theme_white = '#ffffff';
global.theme_black = '#000000';
global.theme_black_opacity = '#000000bb';
global.theme_light = '#FFC586';
global.theme_medium = '#C7945D';
global.theme_light_blue = '#44AEFF';
global.theme_lighter_blue = '#92D2FF';
global.theme_light_red = '#FFDDDD';
global.theme_light_green = '#C0E0B8';
global.theme_lighter = '#EEE7DF';
global.theme_dark = '#866037';
global.theme_darker = '#624A2F';
global.theme_dark_blue = '#003C6A';
global.theme_dark_red = '#F44336';
global.theme_dark_green = '#4D7B4D';
global.theme_blue = '#0061AD';
global.theme_red = '#F25751';
global.theme_green = '#76C73B';
global.theme_yellow = '#F4B549';
global.theme_badge = '#A12E28';
global.theme_placeholder = '#cccccc';
global.theme_light_violet = '#FFAAFF';
global.theme_dark_violet = '#84244D';
global.theme_none = '#00000000';

global.theme_gray = '#eeeeee';
global.theme_xxgray = '#aaaaaa';
global.theme_dark_gray = '#666666';
global.theme_medium_gray = '#888888';
global.theme_light_gray = '#dedede';
global.theme_menu_selected = '#FFFFFF';
global.theme_menu_background_selected = '#FFFFFF';
global.theme_submenu = '#aaaaaa';
global.theme_categorymenu = '#ffffff';

global.theme_selected = '#4D617B';
global.theme_orange = 'orange';
global.theme_violet = 'violet';
global.theme_yellow = 'yellow';
global.theme_brown = 'brown';

global.theme_footer = '#222222';
global.theme_footer_text = '#AAAAAA';
global.theme_footer_text_high = '#CCCCCC';

global.theme_menu = '#FFFFFF22';

global.theme_gradient = 'linear-gradient(to right,#FFFFFF77,#000000AA)';
global.theme_gradient_small = 'linear-gradient(to right,#FFFFFF22,#FFFFFFFF)';
global.theme_gradient_dark = 'linear-gradient(#7B614D,#DAC7B8)';
global.theme_menu_top_gradient = 'linear-gradient(to right,#E7DAD0,#EEEEEE)';
global.theme_gradient_dark_medium = 'linear-gradient(to right,#7B614D,#B29B89)';
global.theme_gradient_dark_dark = 'linear-gradient(to right,#7B614D,#7B614D)';
global.theme_gradient_gray_gray = 'linear-gradient(to right,#DDDDDD,#EFEFEF)';
global.theme_gradient_gray = 'linear-gradient(to right,#eeeeee,#eeeeee)';

// **********************************************************************
// DISPLAY SIZE
// **********************************************************************
global.device_small = 600;
global.device_medium = 992;
global.screen_max = 950;

// **********************************************************************
// FONT SIZE
// **********************************************************************
global.font_xxtiny = 12;
global.font_tiny = 14;
global.font_small = 16;
global.font_normal = 18;
global.font_large = 20;
global.font_xlarge = 24;
global.font_xxlarge = 28;
global.font_xxxlarge = 34;
global.font_label = 28;
global.font_dialog_label = 24; // dialogove boxy - label
global.font_menu = 18; // menu - lava strana
global.font_textlabel = 13; // label pre TextField <p></p>
global.font_categorymenu = 14;
global.font_products_grid = 16;
global.font_banner_label = 36;
global.font_banner_text = 18;
global.font_banner_label_small = 18;
global.font_banner_text_small = 14;
global.font_prices = 22;

// **********************************************************************
// RADIUS
// **********************************************************************
global.radius = 5;
global.padding = 40;
global.padding_small = 10;


// **********************************************************************
// COMPANY - UDAJE O SPOLOCNOSTI
// **********************************************************************
global.company = {
    call: '0905 - 668 294',
    name: 'Datadream s.r.o.',
    street: 'Viničná 52/16',
    psc: '951 93',
    town: 'Machulince',
    state: 'Slovensko',
    mobil1: '0905 - 668 294',
    mobil2: '0944 - 411 144',
    email: 'info@datadream.sk',
    ico: '54084253',
    dic: '2121566524',
    ic_dph: '',
    district: 'Zlaté Moravce',
}
