import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, Link, useNavigate, useParams, Navigate } from "react-router-dom";
import { sk } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, CircularProgress, TextField, Paper, Dialog } from '@mui/material';
import { Button } from '@mui/material';
import { styles } from './styles.js';
import { Line, LoadLanguage, GoHome } from './items.js';
import { faCheck, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { style } from '@mui/system';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ReCAPTCHA from 'react-google-recaptcha'


export const Services = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [height, setHeight] = useState(window.innerHeight);

    var lang = sk;
    let { func } = props;
    let params = useParams();

    const photo = require('./react/app/photo_7.jpg');
    const photo_1 = require('./react/app/photo_8.jpg');
    const photo_2 = require('./react/app/photo_9.jpg');

    useEffect(() => {
        func(false, 1);
        GoHome();

        return () => {
        };

    }, []);


    const db_web = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_list', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

            }

            setBusy(false);

        } catch (error) {
            //Debug(error);
            setBusy(false);
        }
    }



    return (
        <div style={{ ...styles.Block, position: 'relative', marginTop: 0, zIndex: 100 }}>
            <div style={{ ...styles.Block, backgroundColor: '#FAFAFA', paddingTop: 40, paddingBottom: 0, marginBottom: height }}>
                <h1 style={{ ...styles.TextXXLabel }}>{lang.our_services.toUpperCase()}</h1>
                <Line />
                <img src={photo} style={{ width: '100%', marginTop: 30 }}></img>

                <div style={{ ...styles.Block, maxWidth: isSmall ? '95%' : 800 }}>
                    < div style={{ ...styles.BlockLeft, paddingTop: 100, paddingBottom: 100, backgroundColor: global.themne_white }}>
                        <h3 style={{ ...styles.TextXXXLarge }}>Weby a aplikácie</h3>
                        <div style={{ ...styles.BlockLeft, paddingTop: 20, paddingBottom: 20 }}>
                            <li style={{ ...styles.TextNormal, marginBottom: 8, textAlign: 'left' }}>Zhotovenie prezentačných webových stránok a dynamických webových aplikácii a formulárov</li>
                            <li style={{ ...styles.TextNormal, marginBottom: 8, textAlign: 'left' }}>Programovanie e-shopu na mieru, ktorého súčasťou je aj požiadavkám prispôsobený administračný / redakčný systém (CMS)</li>
                            <li style={{ ...styles.TextNormal, marginBottom: 8, textAlign: 'left' }}>Tvorba aplikácii pre mobilné zariadenia Apple OS a Android, UX design a dizajn užívateľského rozhrania (UI) </li>
                            <li style={{ ...styles.TextNormal, marginBottom: 8, textAlign: 'left' }}>Komplexné databázové systémy, CRM, online riešenie pre firmy</li>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockRow }}>
                        <div style={{ width: '50%' }}>
                            <img src={photo_2} style={{ width: '100%' }}></img>
                        </div>
                        <div style={{ width: '50%' }}>
                            <img src={photo_1} style={{ width: '100%' }}></img>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockLeft, paddingTop: 100, paddingBottom: 100, backgroundColor: global.themne_white }}>
                        <h3 style={{ ...styles.TextXXXLarge }}>Design a grafika</h3>
                        <div style={{ ...styles.BlockLeft, paddingTop: 20, paddingBottom: 20 }}>
                            <li style={{ ...styles.TextNormal, marginBottom: 8, textAlign: 'left' }}>Grafický návrh webu, design loga a bannerov</li>
                            <li style={{ ...styles.TextNormal, marginBottom: 8, textAlign: 'left' }}>Fotografovanie produktov na e-shop</li>
                            <li style={{ ...styles.TextNormal, marginBottom: 8, textAlign: 'left' }}>Kamerovanie, strih a publikovanie videa na webové stránky</li>
                        </div>
                    </div>
                </div>

            </div >
        </div >
    )
}