import React, { useEffect, useState, useRef, useCallback } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { Button, Paper } from '@mui/material';
import { sk } from './language.js';
import { styles } from './styles.js';
import { Line, LoadLanguage, GoHome, useScrollDirection } from './items.js';
import { Block } from '@mui/icons-material';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import { faCalendar, faCalendarCheck, faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Parallax } from 'react-scroll-parallax';



export const Main = (props) => {
    const navigate = useNavigate();

    const [isOpen, setOpen] = useState(true);

    const [language, setLanguage] = useState(0);
    const [showOrder, setShowOrder] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const [price1, setPrice1] = useState('');
    const [price2, setPrice2] = useState('');
    const [price3, setPrice3] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const service_1 = require('./react/app/service_1.jpg');
    const service_2 = require('./react/app/service_2.jpg');
    const service_3 = require('./react/app/service_3.jpg');

    const photo_mobil = require('./react/app/mobil.png');
    const photo_tablet = require('./react/app/tablet.png');

    const photo_tablets = require('./react/app/tablet_phone_2.jpg');
    const photo_notebook = require('./react/app/notebook_2.png');
    const photo_1 = require('./react/app/photo_1.jpg');
    const photo_2 = require('./react/app/photo_2.jpg');
    const photo_3 = require('./react/app/photo_3.jpg');
    const photo_4 = require('./react/app/photo_4.jpg');
    const photo_5 = require('./react/app/photo_5.jpg');
    const photo_6 = require('./react/app/photo_6.jpg');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    var lang = sk;
    let { func } = props;


    useEffect(() => {
        func(true, 0); // page index
        GoHome();
        //db_web();

        return () => {
        };

    }, []);

    const db_web = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_list', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

            }

            setBusy(false);

        } catch (error) {
            //Debug(error);
            setBusy(false);
        }
    }

    return (
        <div style={{ ...styles.Block, position: 'relative', marginTop: 0, zIndex: 100 }}>
            <div style={{ ...styles.Block, backgroundColor: global.theme_lighter, paddingBottom: 40, marginBottom: height }}>                

                <div style={{ ...styles.Block, width: '95%', paddingTop: 100, paddingBottom: 100 }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        <Parallax translateY={[0, 20]} scale={[0.9, 1.1]}>
                            <img src={photo_mobil} style={{ maxWidth: isSmall ? 100 : 200 }}></img>
                        </Parallax>
                        <Parallax translateY={[5, 5]} scale={[1.1, 0.9]}>
                            <img src={photo_tablet} style={{ maxWidth: isSmall ? 200 : 480 }}></img>
                        </Parallax>
                    </div>
                    <h1 style={{ ...styles.TextXXLabel, fontWeight: 400, marginTop: 40 }}>Tvorba web stránok, e-shopov a aplikácii</h1>
                    <h2 style={{ ...styles.TextNormal, fontWeight: 400, marginTop: 15 }}>Stačí len vedieť, čo potrebujete a my už vieme ako to dostaneme do reálneho sveta.</h2>
                </div>

                {/*
                <div style={{ marginTop: 40 }}>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                        <img src={photo_tablets} style={{ width: isSmall ? 300 : isMedium ? 450 : 600 }}></img>
                    </AnimationOnScroll>
                </div>
                */}

                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, backgroundColor: global.theme_back, paddingTop: 50, paddingBottom: 50 }}>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRight, width: isSmall ? '100%' : '50%', paddingRight: isSmall ? 0 : 40, marginTop: isSmall ? 0 : isMedium ? 0 : 50 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '95%' : '80%' }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_medium }}>web</p>
                            <Line></Line>
                            <h1 style={{ ...styles.TextXXLarge, marginTop: 5, textAlign: isSmall ? 'center' : 'left' }}>Webové stránky</h1>
                            <p style={{ ...styles.TextSmall, marginTop: 5, textAlign: 'justify', color: global.theme_dark_gray }}>Webová stránka je silný nástroj pre firmy a podnikateľov aby vedeli osloviť svojich zákazníkov aj prostredníctvom webu. Je to aj cenný zdroj informácii. Webové stránky navrhujeme na mieru.</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                            <img src={photo_2} style={{ width: '100%' }}></img>
                        </AnimationOnScroll>
                    </div>
                </div>


                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, backgroundColor: global.theme_white, paddingTop: 50, paddingBottom: 50 }}>
                    {isSmall ? null :
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%' }}>
                            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                                <img src={photo_3} style={{ width: '100%' }}></img>
                            </AnimationOnScroll>
                        </div>
                    }
                    <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '100%' : '50%', paddingLeft: isSmall ? 0 : 40, marginTop: isSmall ? 0 : isMedium ? 0 : 50 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '95%' : '80%' }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_blue, color: global.theme_medium }}>aplikácie</p>
                            <Line></Line>
                            <h1 style={{ ...styles.TextXXLarge, marginTop: 5, textAlign: isSmall ? 'center' : 'left' }}>Aplikácie pre mobilné zariadenia</h1>
                            <p style={{ ...styles.TextSmall, marginTop: 15, textAlign: 'justify', color: global.theme_dark_gray }}>V dnešnej dobe sa asi nikto nezaobíde bez mobilného telefónu. Tvoríme mobilné aplikácie, aby Vaši klienti mohli jednoducho a rýchlo získať to, čo hľadajú a potrebujú.</p>
                        </div>
                    </div>
                    {isSmall ?
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                                <img src={photo_3} style={{ width: '100%' }}></img>
                            </AnimationOnScroll>
                        </div>
                        : null
                    }
                </div>

                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, backgroundColor: global.theme_back, paddingTop: 50, paddingBottom: 50 }}>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRight, width: isSmall ? '100%' : '50%', paddingRight: isSmall ? 0 : 40, marginTop: isSmall ? 0 : isMedium ? 0 : 50 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '95%' : '80%' }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_medium }}>aplikácie</p>
                            <Line></Line>
                            <h1 style={{ ...styles.TextXXLarge, marginTop: 5, textAlign: isSmall ? 'center' : 'left' }}>Webové aplikácie</h1>
                            <p style={{ ...styles.TextSmall, marginTop: 5, textAlign: 'justify', color: global.theme_dark_gray }}>Vypracovanie cenovej ponuky prostredníctvom webu, zadanie zákazky, alebo získanie štatistík sa nezaobíde bez naprogramovania dynamickej webovej aplikácie. Navrhujeme a programujeme webové aplikácie v spojení s databázou, ktoré sa prispôsobujú na základe vložených údajov.</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                            <img src={photo_1} style={{ width: '100%' }}></img>
                        </AnimationOnScroll>
                    </div>
                </div>

                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, backgroundColor: global.theme_white, paddingTop: 50, paddingBottom: 50 }}>
                    {isSmall ? null :
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%' }}>
                            <img src={photo_4} style={{ width: '100%' }}></img>
                        </div>
                    }
                    <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '100%' : '50%', paddingLeft: isSmall ? 0 : 40, marginTop: isSmall ? 0 : isMedium ? 0 : 50 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '95%' : '80%' }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_blue, color: global.theme_medium }}>databáza</p>
                            <Line></Line>
                            <h1 style={{ ...styles.TextXXLarge, marginTop: 5, textAlign: isSmall ? 'center' : 'left' }}>Databázové systémy, CRM</h1>
                            <p style={{ ...styles.TextSmall, marginTop: 15, textAlign: 'justify', color: global.theme_dark_gray }}>Online systémy evidencie zákazníkov, alebo služieb, fakturácie a štatistiky predaja. Toto je moderné fungovanie spoločnosti. Vstup do systému zo všetkých zariadení pomocou webového rozhrania, alebo aplikácie odkiaľkoľvek.</p>
                        </div>
                    </div>
                    {isSmall ?
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                                <img src={photo_4} style={{ width: '100%' }}></img>
                            </AnimationOnScroll>
                        </div>
                        : null
                    }
                </div>

                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, backgroundColor: global.theme_back, paddingTop: 50, paddingBottom: 50 }}>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRight, width: isSmall ? '100%' : '50%', paddingRight: isSmall ? 0 : 40, marginTop: isSmall ? 0 : isMedium ? 0 : 50 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '95%' : '80%' }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_medium }}>e-shop</p>
                            <Line></Line>
                            <h1 style={{ ...styles.TextXXLarge, marginTop: 5, textAlign: isSmall ? 'center' : 'left' }}>E-shopy na mieru (B2C a B2B)</h1>
                            <p style={{ ...styles.TextSmall, marginTop: 5, textAlign: 'justify', color: global.theme_dark_gray }}>E-shop - moderný spôsob nakupovania. V dnešnej dobe stále viac ľudí využíva pohodlný nákup cez internet. Pokiaľ hľadáte riešenie, my ho máme. Navrhnutie e-shopu na predaj daného druhu produktov je prvý krok pri tvorbe internetového obchodu. Prehľadný e-shop, v ktorom sa zákazníci ľahko orientujú je to, na čo myslíme pri programovaní nového internetového obchodu.</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                            <img src={photo_5} style={{ width: '100%' }}></img>
                        </AnimationOnScroll>
                    </div>
                </div>

                <div style={{ ...styles.Block, backgroundColor: global.theme_white, paddingTop: 100, paddingBottom: 50 }}>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: isSmall ? '100%' : '80%' }}>
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '33%', paddingBottom: 50 }}>
                            <div style={{ ...styles.Block }}>
                                <img src={service_1} width={140}></img>
                                <p style={{ ...styles.TextXXLarge, fontWeight: 600, marginTop: 20, color: global.theme_black }}>{lang.service_1}</p>
                            </div>
                            <div style={{ ...styles.Block, marginTop: 10, width: '90%' }}>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', marginTop: 8, color: global.theme_dark_gray }}>Weby a aplikácie</p>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '33%', paddingBottom: 50 }}>
                            <div style={{ ...styles.Block }}>
                                <img src={service_2} width={140}></img>
                                <p style={{ ...styles.TextXXLarge, fontWeight: 600, marginTop: 20, color: global.theme_black }}>{lang.service_2}</p>
                            </div>
                            <div style={{ ...styles.Block, marginTop: 10, width: '90%' }}>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', marginTop: 8, color: global.theme_dark_gray }}>Tvorba loga a návrhy</p>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '33%', paddingBottom: 50 }}>
                            <div style={{ ...styles.Block }}>
                                <img src={service_3} width={140}></img>
                                <p style={{ ...styles.TextXXLarge, fontWeight: 600, marginTop: 20, color: global.theme_black }}>{lang.service_3}</p>
                            </div>
                            <div style={{ ...styles.Block, marginTop: 10, width: '90%' }}>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', marginTop: 8, color: global.theme_dark_gray }}>Animácie, videá, fotografie a grafika</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, backgroundColor: global.theme_lighter, paddingTop: 80, paddingBottom: 50 }}>
                    <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%' }}>
                        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                            <img src={photo_notebook} style={{ width: '100%' }}></img>
                        </AnimationOnScroll>
                    </div>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '100%' : '50%', paddingLeft: isSmall ? 0 : 10, marginTop: isSmall ? 0 : isMedium ? 0 : 50 }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '95%' : '80%' }}>
                            <h1 style={{ ...styles.TextLabel, marginTop: 5, textAlign: isSmall ? 'center' : 'left' }}>Chcete sa niečo opýtať?</h1>
                            <p style={{ ...styles.TextNormal, marginTop: 15, textAlign: 'left', color: global.theme_dark_gray }}>Vypracovanie nezáväznej cenovej ponuky, alebo návrh spolupráce?</p>
                            <a href={'/contact'} style={{ ...styles.Button, ...styles.Block, color: global.theme_white, backgroundColor: global.theme_dark_red, textDecoration: 'none', width: 200, height: 50, marginTop: 40 }}>
                                {lang.write.toUpperCase()}
                            </a>
                        </div>
                    </div>
                </div>

                {/*
                <h1 style={{ ...styles.TextLabel }}>Online riešenie pre pestovateľské pálenice</h1>
                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, marginTop: 50 }}>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRight, width: isSmall ? '100%' : '50%', paddingRight: isSmall ? 0 : 10 }}>
                        <p>demo</p>
                    </div>
                    <div style={{ ...styles.Block, width: isSmall ? '100%' : '50%' }}>
                        <img src={photo_1} style={{ width: '100%' }}></img>
                    </div>
                </div>
                */}
            </div >

        </div>
    )
}